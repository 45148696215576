import { useState, useEffect } from 'react';
import * as localforage from 'localforage';

import {
    isNil,
} from 'lodash';

const DB_NAME = 'LookingForward';
const STEPS_KEY = 'steps';
const VERSION = 1.2;

const windowExists = typeof window !== 'undefined';
if (windowExists) {
    localforage.ready(() => {
        localforage.config({
            driver: localforage.INDEXEDDB,
            name: DB_NAME,
            version: VERSION,
            storeName: `savedData-${VERSION}`,
            description: 'Looking Forward items local DB',
        });
    });
}

const useSavedData = (defaultSteps) => {
    const [steps, setSteps] = useState(defaultSteps);

    /* Get data from the dataBase first time */
    useEffect(() => {
        try {
            if (windowExists) {
                const getItems = async () => {
                    const savedSteps = await localforage.getItem(STEPS_KEY);
                    setSteps(savedSteps || steps);
                };

                getItems();
            }
        } catch (e) {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Save data on DB */
    const saveData = (items) => {
        if (!items) {
            return;
        }

        const newSteps = [...items];
        if (windowExists) {
            localforage.setItem('steps', newSteps).then(() => {
                setSteps(newSteps);
            });
        }
    }

    return [steps, saveData];
};

const getIsThereRoadmap = (steps) => {
    if (isNil(steps) || !steps.length) {
        return false;
    }

    const exists = steps.find(step => {
        const { questions = []} = step;
        const active = questions.find(question => question.active);

        return active ? true : false;
    });

    return exists ? true : false;
};

export {
    DB_NAME,
    useSavedData,
    getIsThereRoadmap
};
